import apiApp from '@/api/config/apiApp'

function getVisitsByResidential (
  residentialId,
  initDate,
  endDate
) {
  return apiApp.get(
  `/visits/?residentialId=${
    residentialId
  }&initDate=${
    initDate
  }&endDate=${
    endDate
  }`)
}

function getVisitsShortstayByResidential (residentialId) {
  return apiApp.get(
  `/visits/shortstay/?residentialId=${
    residentialId
  }`)
}

function changeStatusShortstay (id) {
  return apiApp.put(
  `/visits/shortstay/${id}/toggle`)
}

export {
  getVisitsByResidential,
  getVisitsShortstayByResidential,
  changeStatusShortstay
}

<template>
  <div class="justify-content-start h-100 view-container">
    <HeaderResidential />
    <div class="mt-4">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <Select
            v-model="filters.statusSelected"
            :options="statusList"
            :search="false"
            :multiple="false"
            :clearable="true"
            :placeholder="$t('guard.filterByState')"
          />
        </div>
        <div class="col-md-2">
          <div class="ui icon input" style="width: 99%">
            <input type="text" v-model="filters.text" :placeholder="$t('search')">
            <i class="search icon"></i>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
        <div class="row">
          <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
            <thead>
            <tr>
              <th>{{$t('guard.visitName')}}</th>
              <th style="width: 7%" class="text-center">{{$t('guard.state')}}</th>
              <th style="width: 10%" class="text-center">{{$t('building.apartment')}}</th>
              <th style="width: 10%" class="text-center">{{$t('guard.incomingDate')}}</th>
              <th style="width: 10%" class="text-center">{{$t('guard.exitDate')}}</th>
              <th style="width: 10%" class="text-center">{{$t('guard.approved')}}</th>
            </tr>
            </thead>
            <tbody v-if="residentsOrdered.length === 0">
              <tr>
                <td colspan="8" class="text-center">
                  {{$t('guard.noRegisters')}}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="visit in residentsOrdered"
                :key="visit"
              >
                <td>
                  <h4 class="ui image header">
                    <div class="content">{{ visit.visitorFullName }}</div>
                  </h4>
                </td>
                <td style="text-align: center">
                  <div class="ui toggle checkbox text-center">
                    <input
                      type="checkbox"
                      :checked="visit.status === status.ACTIVE"
                      @change="changeStatus(visit.id)"
                    >
                    <label style="margin-left: -6px"></label>
                  </div>
                  <br>
                  <small>{{ $t(`status.${visit.status}`) }}</small>
                </td>
                <td class="text-center">{{ visit.apartment }}</td>
                <td class="text-center">{{ formatDate(visit.initDate) }}</td>
                <td class="text-center">{{ formatDate(visit.endDate) }}</td>
                <td v-if="!visit.approvedAt" style="text-align: center" class="red">
                  <i class="exclamation triangle icon"></i><br><small>{{ $t('status.PENDING_APPROVAL') }}</small>
                </td>
                <td v-else class="text-center">{{ formatDate(visit.approvedAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import Select from '../../components/form/Select'
import helpers from '../../utils/helpers'
import globals from '../../utils/globals'
import { getVisitsShortstayByResidential, changeStatusShortstay } from '../../api/visit'

export default {
  name: 'Shortstay',
  components: {
    HeaderResidential,
    Select
  },
  data () {
    return {
      showForm: false,
      showUpload: false,
      residentEdition: {},
      visits: [],
      statusList: [
        { id: globals.status.ACTIVE, description: this.$t('status.ACTIVE') },
        { id: globals.status.PENDING, description: this.$t('status.PENDING') }
      ],
      filters: {
        text: '',
        statusSelected: ''
      }
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),

    residentsOrdered () {
      let visits = this.visits

      if (this.filters.text) {
        visits = visits.filter(visit => {
          const name = visit.visitorFullName.toLowerCase() ?? ''
          const apartment = visit.apartment ?? ''
          return name.includes(this.filters.text.toLowerCase()) ||
            apartment.toLowerCase().includes(this.filters.text.toLowerCase())
        })
      }
      if (this.filters.statusSelected) {
        const filterStatusSelected = this.filters.statusSelected.value
        visits = visits.filter(visit => {
          if (filterStatusSelected === this.status.PENDING) {
            return visit.status === this.status.PENDING && visit.activedAt === null
          } else {
            return visit.status === filterStatusSelected
          }
        })
      }

      const pending = visits.filter(x => x.approvedAt === null).sort((a, b) => this.sortByDate(a, b))
      const notPending = visits.filter(x => x.approvedAt !== null).sort((a, b) => this.sortByDate(a, b))
      return pending.concat(notPending)
    }

  },
  created () {
    if (this.visits.length === 0) {
      this.loadVisits()
    }
  },

  methods: {

    ...mapActions('loading', ['setLoading']),

    async loadVisits () {
      this.$store.dispatch('loading/setLoading', true)
      try {
        const resp = await getVisitsShortstayByResidential(this.activeResidential.residentialId)
        resp.data.forEach(visit => {
          visit.initDate = visit.initDate.replace('Z', '')
          visit.endDate = visit.endDate.replace('Z', '')
          if (visit.approvedAt) visit.approvedAt = visit.approvedAt.replace('Z', '')
        })
        // console.log(resp)
        this.visits = resp.data
      } catch (e) {
        console.log('error getting visits')
        console.log(e)
      }
      this.$store.dispatch('loading/setLoading', false)
    },

    formatDate (date) {
      return helpers.formatDateDDMMYYServer(date)
    },

    sortByDate (a, b) {
      return b.initDate - a.initDate
    },

    changeStatus (id) {
      this.setLoading(true)
      changeStatusShortstay(id)
        .then(() => {
          this.loadVisits()
        })
        .catch((err) => {
          console.log(err)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }
</style>
